// extracted by mini-css-extract-plugin
export var __wab_imgSpacer = "PlasmicTermsOfServices-module--__wab_img-spacer--vAsvC";
export var column__lisAz = "PlasmicTermsOfServices-module--column__lisAz--Lg5ih";
export var column__sLsQ = "PlasmicTermsOfServices-module--column__sLsQ--5Qpci";
export var columns = "PlasmicTermsOfServices-module--columns--UHVVs";
export var container3 = "PlasmicTermsOfServices-module--container3--RCc7j";
export var footer = "PlasmicTermsOfServices-module--footer--Hbos7";
export var freeBox__fiuWi = "PlasmicTermsOfServices-module--freeBox__fiuWi--TdRmG";
export var freeBox__hG26 = "PlasmicTermsOfServices-module--freeBox__hG26--SaIGR";
export var freeBox__lwP7C = "PlasmicTermsOfServices-module--freeBox__lwP7C--aQ0Y4";
export var freeBox__nD3Gi = "PlasmicTermsOfServices-module--freeBox__nD3Gi--eoDf-";
export var freeBox__pWlb1 = "PlasmicTermsOfServices-module--freeBox__pWlb1--Mvh3z";
export var freeBox__qIiSi = "PlasmicTermsOfServices-module--freeBox__qIiSi--Ydmy4";
export var h1 = "PlasmicTermsOfServices-module--h1--Fyid-";
export var h3__bfvwH = "PlasmicTermsOfServices-module--h3__bfvwH--3c92F";
export var h3__tOzGm = "PlasmicTermsOfServices-module--h3__tOzGm--usCMl";
export var h3__y7W7 = "PlasmicTermsOfServices-module--h3__y7W7--QKDlX";
export var h3__zp61W = "PlasmicTermsOfServices-module--h3__zp61W--mPGVI";
export var header = "PlasmicTermsOfServices-module--header--T-B0l";
export var img = "PlasmicTermsOfServices-module--img--4uGyB";
export var mainSection = "PlasmicTermsOfServices-module--mainSection--Y9-p+";
export var mainSection2 = "PlasmicTermsOfServices-module--mainSection2--Sfl0O";
export var pageBanner = "PlasmicTermsOfServices-module--pageBanner--9wFeH";
export var root = "PlasmicTermsOfServices-module--root--mkbCt";
export var text___2Slxm = "PlasmicTermsOfServices-module--text___2Slxm--Tlstk";
export var text___8AaAn = "PlasmicTermsOfServices-module--text___8AaAn--AHljp";
export var text__q5EyZ = "PlasmicTermsOfServices-module--text__q5EyZ--ziQkR";
export var text__qqKxR = "PlasmicTermsOfServices-module--text__qqKxR--3gNGv";
export var text__wOjf = "PlasmicTermsOfServices-module--text__wOjf--g8JhR";